var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "m-container",
    { staticClass: "sticky_section pl-2" },
    [
      _c(
        "m-container",
        { staticClass: "historyRightBox" },
        [
          _c("time-display", { attrs: { value: _vm.timeTaken } }),
          _c("question-nav-p", {
            attrs: {
              questions: _vm.questions,
              selections: _vm.selections,
              correct: _vm.correct,
              wrong: _vm.wrong,
            },
          }),
          _c(
            "m-container",
            { staticClass: "mt-3 px-0 start-exam-action" },
            [
              _c(
                "m-container",
                { staticClass: "pa-0" },
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "px-6 ml-3",
                      attrs: { justify: "space-between" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex flex-row align-center justify-center mb-2",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "round-counter-nav d-flex align-center justify-center",
                              staticStyle: { background: "#00796b" },
                            },
                            [
                              _c("p", { staticClass: "mb-0" }, [
                                _vm._v(_vm._s(_vm.correctAnswers)),
                              ]),
                            ]
                          ),
                          _c("p", { staticClass: "mb-0 ml-2" }, [
                            _vm._v("Total Correct"),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex flex-row align-center justify-center mb-2",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "round-counter-nav d-flex align-center justify-center",
                              staticStyle: { background: "#ff5252" },
                            },
                            [
                              _c("p", { staticClass: "mb-0" }, [
                                _vm._v(_vm._s(_vm.wrongAnswers)),
                              ]),
                            ]
                          ),
                          _c("p", { staticClass: "mb-0 ml-2" }, [
                            _vm._v("Total Wrong"),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex flex-row align-center justify-center mb-2",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "round-counter-nav d-flex align-center justify-center",
                              staticStyle: { background: "#808080" },
                            },
                            [
                              _c("p", { staticClass: "mb-0" }, [
                                _vm._v(
                                  _vm._s(_vm.questions.length - _vm.answered)
                                ),
                              ]),
                            ]
                          ),
                          _c("p", { staticClass: "mb-0 ml-2" }, [
                            _vm._v("Total Unanswered"),
                          ]),
                        ]
                      ),
                    ]
                  ),
                  _c("v-divider", { staticClass: "mt-1" }),
                  _c(
                    "v-container",
                    { staticClass: "d-flex align-center justify-center pb-0" },
                    [
                      _c(
                        "div",
                        [
                          _c("v-row", [
                            _c("h4", [
                              _vm._v(
                                "Total score: " + _vm._s(_vm.obtainedScore)
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }