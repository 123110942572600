<template>
  <v-container v-if="renderNow">
    <page-title
      previous-location
      page-title="Exam History Details"
      class="mt-5"
    ></page-title>
    <!-- {{examHistoryDetails}} -->
    <v-container class="pa-0">
      <v-row>
        <v-col md="9" xs="12" class="py-0">
          <div v-for="item in arrayForRender" :key="item.id" class="ma-10">
            <div v-if="item.questions">
              <p class="ml-4">{{ item.item_text }}</p>
              <div v-for="question in item.questions" :key="question.id">
                <question-answer-card-p
                  :value="wrap(examHistoryDetails.answers[question.id])"
                  :submitting="submitting[question.id]"
                  :question="question"
                  :readonly="true"
                  @preview="preview(question.id)"
                  @submit-score="submitScore($event, question.id)"
                />
              </div>
            </div>
            <div v-else>
              <question-answer-card-p
                :value="wrap(examHistoryDetails.answers[item.id])"
                :submitting="submitting[item.id]"
                :question="item"
                :readonly="true"
                @preview="preview(item.id)"
                @submit-score="submitScore($event, item.id)"
              />
            </div>
          </div>
        </v-col>
        <v-col cols="3" class="pl-0 pb-0 hidden-sm-and-down">
          <exam-history-right-box-p
            :time-taken="examHistoryDetails.exam.time_taken"
            :obtained-score="examHistoryDetails.exam.score"
            :answered="examHistoryDetails.exam.answered"
            :correct-answers="examHistoryDetails.exam.correct"
            :wrong-answers="wrongAnswers"
            :questions="examHistoryDetails.questions"
            :stats="answerStats"
          />
        </v-col>
      </v-row>
    </v-container>
    <v-bottom-navigation
      v-if="$vuetify.breakpoint.smAndDown"
      app
      style="z-index: 999"
    >
      <time-display
        :value="examHistoryDetails.exam.timeTaken || 0"
      ></time-display>
      <v-bottom-sheet v-model="sheet">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on"
            ><v-icon>keyboard_arrow_down</v-icon></v-btn
          >
        </template>
        <v-sheet class="text-center" fullscreen>
          <exam-history-right-box-p
            :obtained-score="examHistoryDetails.exam.score"
            :answered="examHistoryDetails.exam.answered"
            :correct-answers="examHistoryDetails.exam.correct"
            :wrong-answers="wrongAnswers"
            :questions="examHistoryDetails.questions"
            :stats="answerStats"
          />
        </v-sheet>
      </v-bottom-sheet>
    </v-bottom-navigation>
  </v-container>
</template>

<script>
import ExamHistoryRightBoxP from "#ecf/exam/components/ExamHistoryRightBoxP.vue";
import TimeDisplay from "#ecf/exam/components/TimeDisplay";
import QuestionAnswerCardP from "#ecf/exam/components/QuestionAnswerCardP";
import { keyBy } from "lodash";

export default {
  name: "ExamHistoryDetails",
  components: {
    TimeDisplay,
    QuestionAnswerCardP,
    ExamHistoryRightBoxP
  },
  props: {
    examId: {
      type: String,
      default: undefined
    },
    studentExamId: {
      type: String,
      default: undefined
    }
  },
  data() {
    return {
      examHistoryDetails: {},
      renderNow: false,
      sheet: false,
      essayMap: {},
      arrayForRender: [],
      submitting: {}
    };
  },
  inject: ["$courseOwnerId", "$courseId"],
  computed: {
    answerStats() {
      const stats = {};
      const answers = this.examHistoryDetails.answers;

      for (let question of this.examHistoryDetails.questions) {
        const correctAnswer = question.answers.filter(a => a.is_correct);
        let isCorrect = false;
        if (question.type === "mcq") {
          var correctId = [];
          for (let c of correctAnswer) {
            correctId.push(c.id);
          }
          isCorrect = answers[question.id]
            ? this.isRight(JSON.parse(answers[question.id]), correctId)
            : undefined;
        } else if (question.type === "fib") {
          isCorrect = answers[question.id]
            ? answers[question.id] === question.answers[0].item_text
            : undefined;
        } else {
          isCorrect = true;
        }
        stats[question.id] = {
          answerId: answers[question.id],
          correctAnswerId: correctAnswer.length > 1 ? correctAnswer.id : null,
          isCorrect: isCorrect,
          isSelected: answers[question.id] !== undefined
        };
      }
      return stats;
    },
    wrongAnswers() {
      return (
        this.examHistoryDetails.exam.answered -
        this.examHistoryDetails.exam.correct
      );
    },
    selected() {
      return Object.values(this.selections).length;
    },
    totalQuestions() {
      return Object.values(this.questions).reduce(
        (acc, qs) => acc + qs.length,
        0
      );
    },
    ignored() {
      return this.totalQuestions - this.selected;
    }
  },
  async created() {
    await this.getExamHistoryDetails();
    this.renderNow = true;
  },
  methods: {
    preview(questionId) {
      this.$router.push({
        name: "exam-assesment",
        params: {
          studentExamId: this.studentExamId,
          studentId: this.examHistoryDetails.exam.student_id,
          questionId
        }
      });
    },
    async submitScore(score, questionId) {
      try {
        this.$set(this.submitting, questionId, true);
        const resp = await this.$store.dispatch("exam/setScore", {
          score,
          questionId,
          studentExamId: this.studentExamId
        });
        if (resp.score)
          this.$set(this.examHistoryDetails.exam, "score", resp.score);
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.$set(this.submitting, questionId, false);
      }
    },
    isRight(studentAnswer, rightAnswer) {
      return studentAnswer.sort().join(",") === rightAnswer.sort().join(",");
    },
    wrap(answer) {
      if (!answer) return [];
      if (typeof answer === "string" && answer.includes("[")) {
        return JSON.parse(answer);
      }
      return [answer];
    },
    async getExamHistoryDetails() {
      this.examHistoryDetails = await this.$store.dispatch(
        "exam/getExamHistoryDetails",
        {
          studentExamId: this.studentExamId
        }
      );
      this.essayMap = keyBy(this.examHistoryDetails.essays, "id");
      let i = 0;
      for (let question of this.examHistoryDetails.questions) {
        i += 1;
        if (question.essay_id) {
          const essay = this.essayMap[question.essay_id];
          if (essay.questions) essay.questions.push(question);
          else {
            this.arrayForRender.push(essay);
            essay.questions = [question];
          }
          question.shouldPick = false;
        } else this.arrayForRender.push(question);
      }
    }
  }
};
</script>

<style lang="scss"></style>
