<template>
  <v-container class="q-nav mt-3 px-0">
    <v-container class="pa-0">
      <p class="mb-2 text-center">Questions Navigation</p>
      <v-divider></v-divider>
      <v-container style="height: 230px" class="q-nav__items px-2">
        <div
          v-for="(question, idx) in questions"
          :key="question.id"
          class="round-counter-nav d-flex align-center justify-center"
          :class="{
            selected:
              selections[question.id] && selections[question.id].length > 0,
            corrected: correct[question.id],
            wronged: wrong[question.id]
          }"
        >
          <p class="mb-0">
            {{ idx + 1 }}
          </p>
        </div>
      </v-container>
    </v-container>
  </v-container>
</template>

<script>
export default {
  name: "QuestionNavP",
  components: {},
  props: {
    questions: {
      type: Array,
      default: () => []
    },
    selections: {
      type: Object,
      default: () => ({})
    },
    correct: {
      type: Object,
      default: () => ({})
    },
    wrong: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {};
  },
  computed: {},
  created() {},
  methods: {}
};
</script>
<style lang="scss">
.q-nav {
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  p {
    font-size: 15px;
  }

  .q-nav__items {
    overflow-y: scroll;
    display: grid;
    grid-template-columns: auto auto auto auto;
    justify-content: space-around;

    .round-counter-nav {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      background-color: #c3c3c3;
      p {
        font-size: 16px;
        font-weight: normal;
        text-align: center;
        color: #000000;
      }
    }

    .round-counter-nav.selected {
      background-color: $primary;

      p {
        font-size: 16px;
        font-weight: normal;
        color: #ffffff !important;
      }
    }
    .round-counter-nav.corrected {
      background-color: #00796b;

      p {
        font-size: 16px;
        font-weight: normal;
        color: #ffffff !important;
      }
    }
    .round-counter-nav.wronged {
      background-color: #ff5252;
      p {
        font-size: 16px;
        font-weight: normal;
        color: #ffffff !important;
      }
    }
  }
}
</style>
