var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "q-nav mt-3 px-0" },
    [
      _c(
        "v-container",
        { staticClass: "pa-0" },
        [
          _c("p", { staticClass: "mb-2 text-center" }, [
            _vm._v("Questions Navigation"),
          ]),
          _c("v-divider"),
          _c(
            "v-container",
            {
              staticClass: "q-nav__items px-2",
              staticStyle: { height: "230px" },
            },
            _vm._l(_vm.questions, function (question, idx) {
              return _c(
                "div",
                {
                  key: question.id,
                  staticClass:
                    "round-counter-nav d-flex align-center justify-center",
                  class: {
                    selected:
                      _vm.selections[question.id] &&
                      _vm.selections[question.id].length > 0,
                    corrected: _vm.correct[question.id],
                    wronged: _vm.wrong[question.id],
                  },
                },
                [
                  _c("p", { staticClass: "mb-0" }, [
                    _vm._v(" " + _vm._s(idx + 1) + " "),
                  ]),
                ]
              )
            }),
            0
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }