<template>
  <m-container class="sticky_section pl-2">
    <m-container class="historyRightBox">
      <time-display :value="timeTaken"></time-display>
      <question-nav-p
        :questions="questions"
        :selections="selections"
        :correct="correct"
        :wrong="wrong"
      ></question-nav-p>
      <m-container class="mt-3 px-0 start-exam-action">
        <m-container class="pa-0">
          <v-row justify="space-between" class="px-6 ml-3">
            <div class="d-flex flex-row align-center justify-center mb-2">
              <div
                style="background: #00796b"
                class="round-counter-nav d-flex align-center justify-center"
              >
                <p class="mb-0">{{ correctAnswers }}</p>
              </div>
              <p class="mb-0 ml-2">Total Correct</p>
            </div>
            <div class="d-flex flex-row align-center justify-center mb-2">
              <div
                style="background: #ff5252"
                class="round-counter-nav d-flex align-center justify-center"
              >
                <p class="mb-0">{{ wrongAnswers }}</p>
              </div>
              <p class="mb-0 ml-2">Total Wrong</p>
            </div>
            <div class="d-flex flex-row align-center justify-center mb-2">
              <div
                style="background: #808080"
                class="round-counter-nav d-flex align-center justify-center"
              >
                <p class="mb-0">{{ questions.length - answered }}</p>
              </div>
              <p class="mb-0 ml-2">Total Unanswered</p>
            </div>
          </v-row>
          <v-divider class="mt-1"></v-divider>
          <v-container class="d-flex align-center justify-center pb-0">
            <div>
              <v-row>
                <h4>Total score: {{ obtainedScore }}</h4>
              </v-row>
            </div>
          </v-container>
        </m-container>
      </m-container>
    </m-container>
  </m-container>
</template>

<script>
import QuestionNavP from "#ecf/exam/components/QuestionNavP.vue";
import TimeDisplay from "#ecf/exam/components/TimeDisplay";
export default {
  name: "ExamHistoryRightBoxP",
  components: { TimeDisplay, QuestionNavP },
  props: {
    questions: {
      type: Array,
      default: () => []
    },
    selections: {
      type: Object,
      default: () => ({})
    },
    timeTaken: {
      type: Number,
      default: 0
    },
    answered: {
      type: Number,
      default: undefined
    },
    stats: {
      type: Object,
      default: () => ({})
    },
    obtainedScore: {
      type: Number,
      default: undefined
    },
    correctAnswers: {
      type: Number,
      default: undefined
    },
    wrongAnswers: {
      type: Number,
      default: undefined
    }
  },
  data() {
    return {};
  },
  computed: {
    correct() {
      const correct = {};

      for (let [questionId, stat] of Object.entries(this.stats)) {
        correct[questionId] = stat.isCorrect;
      }
      return correct;
    },
    wrong() {
      const wrong = {};

      for (let [questionId, stat] of Object.entries(this.stats)) {
        wrong[questionId] = stat.isCorrect === false;
      }
      return wrong;
    },
    remainingTime() {
      const seconds = (this.timeLeft % 60) + "";
      let remaining = Math.trunc(this.timeLeft / 60);
      const minutes = (remaining % 60) + "";
      const hours = Math.trunc(remaining / 60) + "";

      return `${hours.padStart(2, "0")}:${minutes.padStart(
        2,
        "0"
      )}:${seconds.padStart(2, 0)}`;
    }
  },
  // created() {},
  methods: {}
};
</script>
<style lang="scss">
.sticky_section {
  position: sticky;
  position: -webkit-sticky;
  top: 120px;
}
.historyRightBox {
  max-width: 300px;
  min-height: 500px;
  background: #f1f1f1;
  border-radius: 10px;
}
.timer {
  height: 50px;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  h2 {
    font-size: 27px;
    font-weight: 500;
    color: $text;
  }
}
.round-counter-nav {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  p {
    font-size: 16px !important;
    font-weight: normal;
    color: #ffffff;
  }
}
.start-exam-action {
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 10px;

  p {
    font-size: 14px;
  }
}
</style>
