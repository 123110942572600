// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".sticky_section {\n  position: sticky;\n  position: -webkit-sticky;\n  top: 120px;\n}\n.historyRightBox {\n  max-width: 300px;\n  min-height: 500px;\n  background: #f1f1f1;\n  border-radius: 10px;\n}\n.timer {\n  height: 50px;\n  background: #ffffff;\n  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);\n  border-radius: 10px;\n}\n.timer h2 {\n  font-size: 27px;\n  font-weight: 500;\n  color: #3d3d3d;\n}\n.round-counter-nav {\n  width: 35px;\n  height: 35px;\n  border-radius: 50%;\n}\n.round-counter-nav p {\n  font-size: 16px !important;\n  font-weight: normal;\n  color: #ffffff;\n}\n.start-exam-action {\n  background: #ffffff;\n  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);\n  border-radius: 10px;\n}\n.start-exam-action p {\n  font-size: 14px;\n}", ""]);
// Exports
exports.locals = {
	"primary": "#0099dc",
	"orange": "#FC6222",
	"decline": "#ff4848"
};
module.exports = exports;
